import react, { Component } from "react";
import "./Container.css"
import json from "../../list.json";

class Container extends Component {
    state = {
        show: false
    }
    handleClose = () => this.setState({ show: false });
    handleShow = () => this.setState({ show: true });
    changelocation = (url)=>{
        window.location.href = url;
    }
    render() {
        let url = Object.keys(json).map((item,key) => {
            console.log(item)
            let infobox = json[item].map((row, index) => {
                return (
                    <div className="site" key={index} onClick={()=>this.changelocation(row["url"])}>
                        {row["name"]}
                    </div>
                )
            })
            return (
                <div className="infobox" key={key}>
                        <div className="infobox_title">
                            {item}
                        </div>
                        <div>
                            
                        </div>
                        <div className="sites" >
                            {infobox}
                        </div>
                    </div>
            )

        })
        return (
            <div className="Container">
                <div className="title">
                    <div className="titleheader">
                        INVISIBLE FUN STUDIO
                    </div>
                    <div className="subtitle">
                        Internal Portal
                    </div>

                </div>

                <hr className="line"></hr>
                <div className="portal">
                    {url}
                </div>

            </div>
        )
    }
}

export default Container;
